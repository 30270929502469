<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form PQR'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_pqr_label"
                  label="No. PQR :"
                  label-for="no_pqr"
                >
                  <b-form-input
                    id="no_pqr"
                    v-model="form.no_pqr"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="proses_pengelasan_label"
                  label="Proses Pengelasan :"
                  label-for="proses_pengelasan"
                >
                  <b-form-input
                    id="proses_pengelasan"
                    v-model="form.proses_pengelasan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_p_label"
                  label="P No. :"
                  label-for="no_p"
                >
                  <b-form-input
                    id="no_p"
                    v-model="form.no_p"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_group_label"
                  label="Group No.:"
                  label-for="no_group"
                >
                  <b-form-input
                    id="no_group"
                    v-model="form.no_group"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_f_label"
                  label="F No. :"
                  label-for="no_f"
                >
                  <b-form-input
                    id="no_f"
                    v-model="form.no_f"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_a_label"
                  label="A No. :"
                  label-for="no_a"
                >
                  <b-form-input
                    id="no_a"
                    v-model="form.no_a"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="diameter_label"
                  label="Diameter :"
                  label-for="diameter"
                >
                  <b-form-input
                    id="diameter_max"
                    v-model="form.diameter_max"
                    type="text"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tebal_label"
                  label="Tebal :"
                  label-for="tebal"
                >
                  <b-form-input
                    id="tebal_max"
                    v-model="form.tebal_max"
                    type="text"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="polaritas_label"
                  label="Polaritas :"
                  label-for="polaritas"
                >
                  <b-form-input
                    id="polaritas"
                    v-model="form.polaritas"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="posisi_label"
                  label="Posisi :"
                  label-for="posisi"
                >
                  <b-form-input
                    id="posisi"
                    v-model="form.posisi"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="preheat_label"
                  label="Preheat :"
                  label-for="preheat"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    v-model="form.preheat"
                    :aria-describedby="ariaDescribedby"
                    name="preheat"
                    :value="true"
                    >Iya</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.preheat"
                    :aria-describedby="ariaDescribedby"
                    name="preheat"
                    :value="false"
                    >Tidak</b-form-radio
                  >
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pwht_label"
                  label="PWHT :"
                  label-for="pwht"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio
                    v-model="form.pwht"
                    :aria-describedby="ariaDescribedby"
                    name="pwht"
                    :value="true"
                    >Iya</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.pwht"
                    :aria-describedby="ariaDescribedby"
                    name="pwht"
                    :value="false"
                    >Tidak</b-form-radio
                  >
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="project_label"
                  label="Project :"
                  label-for="project"
                >
                  <b-form-input
                    id="project"
                    v-model="form.project"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="attachment_label"
                  label="Attachment :"
                  label-for="attachment"
                >
                  <b-form-file
                    id="attachment"
                    v-model="form.attachment"
                    placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
                  <span v-for="(val, index) in errors" :key="index">
                    <h6>{{ index.replace(/_/g, ' ').toUpperCase() + ':' }}</h6>
                    <ul>
                      <li v-for="(arr, i) in val" :key="i">
                        {{ arr }}
                      </li>
                    </ul>
                    <br />
                  </span>
                </b-alert>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/kualifikasi_las/pqr')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'

import $ from 'jquery'

export default {
  mixins: [validationMixin],
  name: 'editpqr',
  data() {
    return {
      form: {
        no_pqr: null,
        proses_pengelasan: null,
        no_group: null,
        no_p: null,
        no_f: null,
        no_a: null,
        diameter_max: null,
        tebal_max: null,
        polaritas: null,
        posisi: null,
        preheat: false,
        pwht: false,
        project: null,
        attachment: null,
      },

      errors: [],
      showDismissibleAlert: false,
      loading: false,
      id: 0,
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'PQR', route: '/kualifikasi_las/pqr' },
      { title: 'Edit PQR' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id

    ApiService.get('/kualifikasi_las/pqr/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.no_pqr = data.data.no_pqr
          self.form.proses_pengelasan = data.data.proses_pengelasan
          self.form.no_group = data.data.no_group
          self.form.no_p = data.data.no_p
          self.form.no_f = data.data.no_f
          self.form.no_a = data.data.no_a
          self.form.diameter_max = data.data.diameter_max
          self.form.tebal_max = data.data.tebal_max
          self.form.polaritas = data.data.polaritas
          self.form.posisi = data.data.posisi
          self.form.preheat = data.data.preheat
          self.form.pwht = data.data.pwht
          self.form.project = data.data.project
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    onSubmit() {
      // event.preventDefault()
      var self = this
      self.loading = true
      const no_pqr = self.form.no_pqr
      const proses_pengelasan = self.form.proses_pengelasan
      const no_group = self.form.no_group
      const no_p = self.form.no_p
      const no_f = self.form.no_f
      const no_a = self.form.no_a
      const diameter_max = self.form.diameter_max
      const tebal_max = self.form.tebal_max
      const polaritas = self.form.polaritas
      const posisi = self.form.posisi
      const preheat = self.form.preheat
      const pwht = self.form.pwht
      const project = self.form.project
      const attachment = self.form.attachment

      const dataku = new FormData()
      if (no_pqr) dataku.append('no_pqr', no_pqr)
      if (proses_pengelasan) dataku.append('proses_pengelasan', proses_pengelasan)
      if (no_group) dataku.append('no_group', no_group)
      if (no_p) dataku.append('no_p', no_p)
      if (no_f) dataku.append('no_f', no_f)
      if (no_a) dataku.append('no_a', no_a)
      if (diameter_max) dataku.append('diameter', diameter_max)
      if (tebal_max) dataku.append('tebal', tebal_max)
      if (polaritas) dataku.append('polaritas', polaritas)
      if (posisi) dataku.append('posisi', posisi)
      dataku.append('preheat', preheat)
      dataku.append('pwht', pwht)
      if (project) dataku.append('project', project)

      if (attachment) {
        dataku.append('attachment', attachment)
      }

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + `/kualifikasi_las/pqr/update/${self.id}`,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/kualifikasi_las/pqr')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
